import React from 'react'

import Card from "./components/Card";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import ImageEdit from "./components/common/ImageEdit";
import Switch from "react-switch";
import SmartList from "./components/common/SmartList";
import TextEditor from "./components/common/TextEditor";
import CustomSelect from "./components/common/Select";
import AsyncSelect from "./components/common/AsyncSelect";
import General from "../utils/General";

const ICONS = [
  'icon-User',
  'icon-Search-People',
  'icon-Search-onCloud',
  'icon-Data-Search',
  'icon-File-Search',
  'icon-Folder-Search',
  'icon-Mail-Search',
  'icon-Star',
  'icon-Hand-Touch',
  'icon-Life-Safer',
  'icon-Eye',
  'icon-Tag',
  'icon-Bookmark',
  'icon-Heart',
  'icon-Thumb',
  'icon-Upload-toCloud',
  'icon-Smartphone',
  'icon-Gear',
  'icon-Gears',
  'icon-Wrench',
  'icon-Volume-Up',
  'icon-Volume-Down',
  'icon-Direction-North',
  'icon-Direction-East',
  'icon-Direction-South',
  'icon-Direction-West'
]

export default class Channel extends React.Component {
  constructor(props) {
    super(props)

    let channelSlug = this.props.match.params.channel_slug

    this.state = {
      channelSlug
    }
    this.documentsTable = React.createRef()
  }

  componentDidMount(){
    Backend.getChannelBySlug(this.state.channelSlug).then(channel => {
      this.setState({channel}, () => this._loadChannelInfo(channel))
    }).catch(e => Notify.error(e.message))
  }

  _loadChannelInfo(channel){
    Backend.getChannelInfo(channel).then(channelInfo => {
      this.setState({channelInfo})
    }).catch(e => Notify.error(e.message))
  }

  _uploadImageFile(file, attribute, imageAttribute=null){
    let {
      channel
    } = this.state

    this.setState({ loading: true })
    Backend.uploadImage(file, "company")
      .then(image => {
        if(attribute === "settings"){
          channel[attribute][imageAttribute] = image.image_url
        }else{
          channel[attribute] = image.image_url
        }
        this.setState({ loading: false, channel })
      })
      .catch(error => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _update(){
    let {
      channel
    } = this.state

    Backend.updateChannel(channel).then(e => {
      Notify.success("Channel Updated")
    }).catch(e => Notify.error(e.message))
  }

  _updateInfo(type){
    let {
      channel,
      channelInfo
    } = this.state

    let error = null

    channelInfo[type].map(entry => {
      if(type === "boxes"){
        if(!entry.icon){
          error = 'Please enter icon'
        }else if(!entry.title){
          error = 'Please enter the title for all entries'
        }else if(!entry.text){
          error = 'Please enter the subtitle for all entries'
        }else if(!entry.cta_text){
          error = 'Please enter the cta text for all entries'
        }else if(!entry.cta_url){
          error = 'Please enter the cta link for all entries'
        }
      }else{
        if(!entry.title){
          error = 'Please enter the title for all entries'
        }else if(!entry.text){
          error = 'Please enter the subtitle for all entries'
        }
      }
    })

    if(error){
      Notify.error(error)
      return
    }

    Backend.updateChannelInfo(channel, channelInfo).then(e => {
      Notify.success("Company Updated")
    }).catch(e => Notify.error(e.message))
  }

  _renderBoxInput(box, index){
    let {
      channelInfo
    } = this.state

    return (
      <>
        <div className="form-group">
          <label style={{marginRight: 10}}>Icon</label>
          <small><a target="_blank" href="https://iconsmind.com/view_icons/">view icons</a></small>
          <select
            className="form-control"
            value={box.icon}
            onChange={e => {
              channelInfo.boxes[index].icon = e.target.value
              this.setState({channelInfo})
            }}
          >
            <option value="">No Icon</option>
            {
              ICONS.map(icon => (
                <option value={icon}>{icon}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group">
          <label>Title</label>
          <input
            className="form-control"
            type="text"
            value={box.title}
            onChange={e => {
              channelInfo.boxes[index].title = e.target.value
              this.setState({channelInfo})
            }}
          />
        </div>
        <div className="form-group">
          <label>Subtitle</label>
          <input
            className="form-control"
            type="text"
            value={box.text}
            onChange={e => {
              channelInfo.boxes[index].text = e.target.value
              this.setState({channelInfo})
            }}
          />
        </div>
        <div className="form-group">
          <label>CTA</label>
          <input
            className="form-control"
            type="text"
            value={box.cta_text}
            onChange={e => {
              channelInfo.boxes[index].cta_text = e.target.value
              this.setState({channelInfo})
            }}
          />
        </div>
        <div className="form-group">
          <label>CTA Link</label>
          <input
            className="form-control"
            type="text"
            value={box.cta_url}
            onChange={e => {
              channelInfo.boxes[index].cta_url = e.target.value
              this.setState({channelInfo})
            }}
          />
        </div>
        <hr/>
      </>
    )
  }

  _renderContentInput(content, index){
    let {
      channelInfo
    } = this.state

    return (
      <>
        <div className="form-group">
          <label>Title</label>
          <input
            className="form-control"
            type="text"
            value={content.title}
            onChange={e => {
              channelInfo.content[index].title = e.target.value
              this.setState({channelInfo})
            }}
          />
        </div>
        <div className="form-group">
          <label>Text</label>
          <textarea
            className="form-control"
            rows="3"
            value={content.text}
            onChange={e => {
              channelInfo.content[index].text = e.target.value
              this.setState({channelInfo})
            }}
          />
        </div>
        <hr/>
      </>
    )
  }

  _renderRedirectPercentage(){
    let {
      channel
    } = this.state

    let options = []
    for(let i = 0; i <= 100; i+=5){
      options.push(
        <option value={i} selected={channel.feed_redirect === i}>{i}</option>
      )
    }

    return options
  }

  _renderFeaturedJob(job, index){
    let {
      channel
    } = this.state

    return (
      <>
        <div className="form-group">
          <label>Select Company</label>
          <CustomSelect
            className="async-select-paginate"
            classNamePrefix="async-select-paginate"
            isSearchable={true}
            options={channel.companies_details.map(company => {return {...company, label:`${company.name}, ${company.live_jobs} Jobs`, value:company.id}})}
            value={{...channel.featured_jobs.results[index]?.company, label:`${channel.featured_jobs.results[index].company ? `${channel.featured_jobs.results[index]?.company?.name}, ${channel.featured_jobs.results[index]?.company?.live_jobs} Jobs` : 'Search Companies'}`, value:channel.featured_jobs.results[index]?.company?.id}}
            onChange={option => {
              if(!channel.featured_jobs.results){
                channel.featured_jobs.results = []
              }
              channel.featured_jobs.results[index] = {company: option}
              this.setState({channel})
            }}
          />
        </div>
        {
          channel.featured_jobs.results[index].company &&
          <div className="form-group">
            <label>Select Job</label>
            <AsyncSelect
              endpoint={`${window.Api.Companies}/${channel.featured_jobs.results[index].company?.id}/jobs`}
              filter={'status=active'}
              orderBy={"title"}
              value={channel.featured_jobs.results[index].title ? General.getSingleAsyncOption(channel.featured_jobs.results[index], 'title') : ''}
              onSelected={job => {
                channel.featured_jobs.results[index] = {
                  ...channel.featured_jobs.results[index]?.company, ...job,
                  label: job.title,
                  value: job.id
                }
                this.setState({channel})
              }}
              getOptions={jobs => {
                return jobs.map(job => ({
                    value: job.id,
                    label: `${job.title} | ${job.location.address}, ${job.location.city}, ${job.location.country}`,
                    data: job
                  })
                )
              }}
            />
          </div>
        }
        <hr/>
      </>
    )
  }

  render() {

    let {
      channel,
      channelInfo,
    } = this.state

    if(!channel) return null

    return (
      <>
        <div className="row">
          <div className="col-lg-6 p-0">
            <Card
              title={"General"}
            >
              <div className="form-group">
                <label>Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.name}
                  onChange={e => {
                    channel.name = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Title</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.settings.banner_title}
                  onChange={e => {
                    channel.settings.banner_title = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Subtitle</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.caption}
                  onChange={e => {
                    channel.caption = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Primary Color</label>
                <input
                  className="form-control"
                  type="color"
                  value={channel.colour}
                  onChange={e => {
                    channel.colour = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Secondary Color</label>
                <input
                  className="form-control"
                  type="color"
                  value={channel.settings.secondary_color}
                  onChange={e => {
                    channel.settings.secondary_color = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Banner Height</label>
                    <input
                      className="form-control"
                      type="number"
                      value={channel.settings.banner_size}
                      onChange={e => {
                        channel.settings.banner_size = e.target.value
                        this.setState({channel})
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Banner Overlay</label>
                    <input
                      className="form-control"
                      type="number"
                      value={channel.settings.banner_overlay}
                      onChange={e => {
                        channel.settings.banner_overlay = e.target.value
                        this.setState({channel})
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Feed Size</label>
                    <input
                      className="form-control"
                      type="number"
                      value={channel.settings.feed_no}
                      onChange={e => {
                        channel.settings.feed_no = e.target.value
                        this.setState({channel})
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Primary CTA</label>
                    <input
                      className="form-control"
                      type="text"
                      value={channel.settings.menu_alert_cta}
                      onChange={e => {
                        channel.settings.menu_alert_cta = e.target.value
                        this.setState({channel})
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Secondary CTA</label>
                    <input
                      className="form-control"
                      type="text"
                      value={channel.settings.menu_job_cta}
                      onChange={e => {
                        channel.settings.menu_job_cta = e.target.value
                        this.setState({channel})
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Secondary CTA Link</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.settings.menu_job_url}
                  onChange={e => {
                    channel.settings.menu_job_url = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Menu Logo URL</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.settings.menu_logo_url}
                  onChange={e => {
                    channel.settings.menu_logo_url = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Default Search Term</label>
                    <input
                      className="form-control"
                      type="text"
                      value={channel.default_search_term}
                      onChange={e => {
                        channel.default_search_term = e.target.value
                        this.setState({channel})
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>IIQ Pub Number</label>
                    <input
                      className="form-control"
                      type="text"
                      value={channel.pubnum}
                      onChange={e => {
                        channel.pubnum = e.target.value
                        this.setState({channel})
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>GTM ID</label>
                    <input
                        className="form-control"
                        type="text"
                        value={channel.settings.gtm_id}
                        onChange={e => {
                          channel.settings.gtm_id = e.target.value
                          this.setState({channel})
                        }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Google Autocomplete Key</label>
                    <input
                        className="form-control"
                        type="text"
                        value={channel.settings.gmaps_key}
                        onChange={e => {
                          channel.settings.gmaps_key = e.target.value
                          this.setState({channel})
                        }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Indeed API</label><br/>
                    <Switch
                      className="react-switch"
                      onChange={checked => {
                        channel.indeed_api = checked
                        this.setState({channel})
                      }}
                      checked={channel.indeed_api}
                      aria-labelledby="neat-label"
                      onColor="#5867dd"
                      offColor="#d5d5d5"
                      checkedIcon={null}
                      uncheckedIcon={null}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Generate Export</label><br/>
                    <Switch
                      className="react-switch"
                      onChange={checked => {
                        channel.feed_export = checked
                        this.setState({channel})
                      }}
                      checked={channel.feed_export}
                      aria-labelledby="neat-label"
                      onColor="#5867dd"
                      offColor="#d5d5d5"
                      checkedIcon={null}
                      uncheckedIcon={null}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Redirect %</label><br/>
                    <select
                      className={"form-control"}
                      onChange={e => {
                        channel.feed_redirect = parseInt(e.target.value)
                        this.setState({channel})
                      }}
                    >
                      { this._renderRedirectPercentage() }
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Show Articles</label><br/>
                    <Switch
                      className="react-switch"
                      onChange={checked => {
                        channel.settings.show_articles = checked
                        this.setState({channel})
                      }}
                      checked={channel.settings.show_articles}
                      aria-labelledby="neat-label"
                      onColor="#5867dd"
                      offColor="#d5d5d5"
                      checkedIcon={null}
                      uncheckedIcon={null}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Show Sub Channels</label><br/>
                    <Switch
                      className="react-switch"
                      onChange={checked => {
                        channel.settings.show_sub_channels = checked
                        this.setState({channel})
                      }}
                      checked={channel.settings.show_sub_channels}
                      aria-labelledby="neat-label"
                      onColor="#5867dd"
                      offColor="#d5d5d5"
                      checkedIcon={null}
                      uncheckedIcon={null}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Transparent Navbar</label><br/>
                    <Switch
                      className="react-switch"
                      onChange={checked => {
                        channel.settings.transparent_navbar = checked
                        this.setState({channel})
                      }}
                      checked={channel.settings.transparent_navbar}
                      aria-labelledby="neat-label"
                      onColor="#5867dd"
                      offColor="#d5d5d5"
                      checkedIcon={null}
                      uncheckedIcon={null}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Show Info Boxes</label><br/>
                    <Switch
                      className="react-switch"
                      onChange={checked => {
                        channel.settings.show_info_boxes = checked
                        this.setState({channel})
                      }}
                      checked={channel.settings.show_info_boxes}
                      aria-labelledby="neat-label"
                      onColor="#5867dd"
                      offColor="#d5d5d5"
                      checkedIcon={null}
                      uncheckedIcon={null}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Show Info Tabs</label><br/>
                    <Switch
                      className="react-switch"
                      onChange={checked => {
                        channel.settings.show_info_tabs = checked
                        this.setState({channel})
                      }}
                      checked={channel.settings.show_info_tabs}
                      aria-labelledby="neat-label"
                      onColor="#5867dd"
                      offColor="#d5d5d5"
                      checkedIcon={null}
                      uncheckedIcon={null}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Show Featured Job</label><br/>
                    <Switch
                      className="react-switch"
                      onChange={checked => {
                        channel.settings.detail_featured = checked
                        this.setState({channel})
                      }}
                      checked={channel.settings.detail_featured}
                      aria-labelledby="neat-label"
                      onColor="#5867dd"
                      offColor="#d5d5d5"
                      checkedIcon={null}
                      uncheckedIcon={null}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-lg-6 p-0">
            <Card
              title={"Images"}
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Favicon</label>
                    <ImageEdit
                      image={channel.settings.favicon}
                      type={"avatar"}
                      hidePreview={false}
                      deletable={true}
                      uploadImageFile={file => this._uploadImageFile(file, "settings", "favicon")}
                      onDeleteClicked={() => {
                        channel.settings.favicon = null
                        this.setState({channel})
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Logo</label>
                    <ImageEdit
                      image={channel.logo}
                      type={"avatar"}
                      hidePreview={false}
                      deletable={true}
                      uploadImageFile={file => this._uploadImageFile(file, "logo")}
                      onDeleteClicked={() => {
                        channel.logo = null
                        this.setState({channel})
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Menu Logo</label>
                    <ImageEdit
                      image={channel.settings.menu_logo}
                      type={"avatar"}
                      hidePreview={false}
                      deletable={true}
                      uploadImageFile={file => this._uploadImageFile(file, "settings", "menu_logo")}
                      onDeleteClicked={() => {
                        channel.settings.menu_logo = null
                        this.setState({channel})
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Menu Secondary Logo</label>
                    <ImageEdit
                      image={channel.settings.menu_secondary_logo}
                      type={"avatar"}
                      hidePreview={false}
                      deletable={true}
                      uploadImageFile={file => this._uploadImageFile(file, "settings", "menu_secondary_logo")}
                      onDeleteClicked={() => {
                        channel.settings.menu_secondary_logo = null
                        this.setState({channel})
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Banner</label>
                <ImageEdit
                  image={channel.banner}
                  type={"banner"}
                  hidePreview={false}
                  deletable={true}
                  uploadImageFile={file => this._uploadImageFile(file, "banner")}
                  onDeleteClicked={() => {
                    channel.banner = null
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Card</label>
                <ImageEdit
                  image={channel.card}
                  type={"banner"}
                  hidePreview={false}
                  deletable={true}
                  uploadImageFile={file => this._uploadImageFile(file, "card")}
                  onDeleteClicked={() => {
                    channel.card = null
                    this.setState({channel})
                  }}
                />
              </div>
            </Card>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 p-0">
            <Card
              title={"Featured Companies"}
            >
              <SmartList
                items={channel.featured_companies}
                renderItem={(company, index) => {
                  return (
                    <>
                      <CustomSelect
                        className="async-select-paginate"
                        classNamePrefix="async-select-paginate"
                        isSearchable={true}
                        options={channel.companies_details.map(company => {return {...company, label:`${company.name}, ${company.live_jobs} Jobs`, value:company.id}})}
                        value={{...channel.featured_companies[index], label:`${channel.featured_companies[index]?.name}, ${channel.featured_companies[index]?.live_jobs} Jobs`, value:channel.featured_companies[index]?.id}}
                        onChange={option => {
                          channel.featured_companies[index] = option
                          this.setState({channel})
                        }}
                      />
                    </>
                  )
                }}
                onAddItemPressed={() => {
                  channel.featured_companies.push({})
                  this.setState({channel})
                }}
                onUpdated={items => {
                  channel.featured_companies = items
                  this.setState({channel})
                }}
              />
            </Card>
          </div>
          <div className="col-lg-6 p-0">
            <Card
              title={"Featured Jobs"}
            >
              <SmartList
                items={channel.featured_jobs.results}
                maximumItems={15}
                renderItem={(job, index) => this._renderFeaturedJob(job, index)}
                onAddItemPressed={() => {
                  channel.featured_jobs.results.push({})
                  this.setState({channel})
                }}
                onUpdated={items => {
                  channel.featured_jobs.results = items
                  this.setState({channel})
                }}
              />
            </Card>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 p-0">
            <Card
              title={"Titles & Subtitles"}
            >
              <div className="form-group">
                <label>Featured Companies Title</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.settings.featured_companies_title}
                  onChange={e => {
                    channel.settings.featured_companies_title = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Featured Companies Subtitle</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.settings.featured_companies_subtitle}
                  onChange={e => {
                    channel.settings.featured_companies_subtitle = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Companies Title</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.settings.companies_title}
                  onChange={e => {
                    channel.settings.companies_title = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Right Panel Title</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.settings.detail_title}
                  onChange={e => {
                    channel.settings.detail_title = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Right Panel Subtitle</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.settings.detail_subline}
                  onChange={e => {
                    channel.settings.detail_subline = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Floating Panel Title</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.settings.floating_title}
                  onChange={e => {
                    channel.settings.floating_title = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Floating Panel Subtitle</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.settings.floating_subline}
                  onChange={e => {
                    channel.settings.floating_subline = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
            </Card>
          </div>
          <div className="col-lg-6 p-0">
            <Card
              title={"CTAs & Links"}
            >
              <div className="form-group">
                <label>Right Panel Primary CTA</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.settings.detail_alert_cta}
                  onChange={e => {
                    channel.settings.detail_alert_cta = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Right Panel Secondary CTA</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.settings.detail_job_cta}
                  onChange={e => {
                    channel.settings.detail_job_cta = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Right Panel Secondary CTA Link</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.settings.detail_job_url}
                  onChange={e => {
                    channel.settings.detail_job_url = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Floating CTA</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.settings.floating_text}
                  onChange={e => {
                    channel.settings.floating_text = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
              <div className="form-group">
                <label>Floating CTA Link</label>
                <input
                  className="form-control"
                  type="text"
                  value={channel.settings.floating_url}
                  onChange={e => {
                    channel.settings.floating_url = e.target.value
                    this.setState({channel})
                  }}
                />
              </div>
            </Card>
          </div>
        </div>

        <div className="row">
          <Card
            title={"Page Urls"}
          >
            <div className="row">
              <div className="col-lg-6 p-3">
                <div className="form-group">
                  <label>Homepage</label>
                  <input
                    className="form-control"
                    type="text"
                    value={channel.routes.home_url}
                    onChange={e => {
                      channel.routes.home_url = e.target.value
                      this.setState({channel})
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Company</label>
                  <input
                    className="form-control"
                    type="text"
                    value={channel.routes.company_url}
                    onChange={e => {
                      channel.routes.company_url = e.target.value
                      this.setState({channel})
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Company Jobs</label>
                  <input
                    className="form-control"
                    type="text"
                    value={channel.routes.company_jobs_url}
                    onChange={e => {
                      channel.routes.company_jobs_url = e.target.value
                      this.setState({channel})
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Job</label>
                  <input
                    className="form-control"
                    type="text"
                    value={channel.routes.job_url}
                    onChange={e => {
                      channel.routes.job_url = e.target.value
                      this.setState({channel})
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Channel</label>
                  <input
                    className="form-control"
                    type="text"
                    value={channel.routes.channel_url}
                    onChange={e => {
                      channel.routes.channel_url = e.target.value
                      this.setState({channel})
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 p-3">
                <div className="form-group">
                  <label>Article</label>
                  <input
                    className="form-control"
                    type="text"
                    value={channel.routes.article_url}
                    onChange={e => {
                      channel.routes.article_url = e.target.value
                      this.setState({channel})
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Companies</label>
                  <input
                    className="form-control"
                    type="text"
                    value={channel.routes.companies_url}
                    onChange={e => {
                      channel.routes.companies_url = e.target.value
                      this.setState({channel})
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Jobs</label>
                  <input
                    className="form-control"
                    type="text"
                    value={channel.routes.jobs_url}
                    onChange={e => {
                      channel.routes.jobs_url = e.target.value
                      this.setState({channel})
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Articles</label>
                  <input
                    className="form-control"
                    type="text"
                    value={channel.routes.articles_url}
                    onChange={e => {
                      channel.routes.articles_url = e.target.value
                      this.setState({channel})
                    }}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className="row">
          <Card
            title={"Footer Urls"}
          >
            <div className="row">
              <div className="col-lg-6 p-3">
                <div className="form-group">
                  <label>Privacy Policy</label>
                  <input
                    className="form-control"
                    type="text"
                    value={channel.settings.privacy_policy_url}
                    onChange={e => {
                      channel.settings.privacy_policy_url = e.target.value
                      this.setState({channel})
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Cookie Policy</label>
                  <input
                    className="form-control"
                    type="text"
                    value={channel.settings.cookie_policy_url}
                    onChange={e => {
                      channel.settings.cookie_policy_url = e.target.value
                      this.setState({channel})
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 p-3">
                <div className="form-group">
                  <label>Use Policy</label>
                  <input
                    className="form-control"
                    type="text"
                    value={channel.settings.use_policy_url}
                    onChange={e => {
                      channel.settings.use_policy_url = e.target.value
                      this.setState({channel})
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Terms& Conditions</label>
                  <input
                    className="form-control"
                    type="text"
                    value={channel.settings.terms_conditions_url}
                    onChange={e => {
                      channel.settings.terms_conditions_url = e.target.value
                      this.setState({channel})
                    }}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className="row">
          <div className="col-lg-12 p-0">
            <Card
              title={"Cookie Policy"}
            >
              <div className="form-group">
                <TextEditor
                  text={ channel.settings.cookie }
                  className="form-control"
                  placeholder="Cookie policy"
                  onUpdated={text => {
                    channel.settings.cookie = text
                    this.setState({ channel})
                  }}
                />
              </div>
            </Card>
          </div>
        </div>

        <div>
          <div className="form-group pull-right">
            <div className="text-center">
              <button
                id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
                onClick={() => this._update()}
              >
                Update
              </button>
            </div>
          </div>
        </div>

        <br/><br/><br/><br/>

        {
          channelInfo &&
          <div className="row">
            <div className="col-lg-6 p-0">
              <Card
                title={"3 Boxes"}
              >
                <SmartList
                  items={channelInfo.boxes}
                  maximumItems={3}
                  renderItem={(box, index) => this._renderBoxInput(box, index)}
                  onAddItemPressed={() => {
                    channelInfo.boxes.push({type: "box"})
                    this.setState({channelInfo})
                  }}
                  onUpdated={items => {
                    channelInfo.boxes = items
                    this.setState({channelInfo})
                  }}
                />
                <div className="form-group float-right">
                  <div className="text-center">
                    <button
                      id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
                      onClick={() => this._updateInfo('boxes')}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-lg-6 p-0">
              <Card
                title={"Content Tabs"}
              >
                <SmartList
                  items={channelInfo.content}
                  renderItem={(content, index) => this._renderContentInput(content, index)}
                  onAddItemPressed={() => {
                    channelInfo.content.push({type: "content"})
                    this.setState({channelInfo})
                  }}
                  onUpdated={items => {
                    channelInfo.content = items
                    this.setState({channelInfo})
                  }}
                />
                <div className="form-group float-right">
                  <div className="text-center">
                    <button
                      id="escalate" type="button" className="btn btn-success" style={{width: '100%'}}
                      onClick={() => this._updateInfo('content')}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        }


      </>
    )
  }
}
